$loadingSpinnerSizeSml: 1.5rem;
$loadingSpinnerSizeMed: 2.5rem;
$loadingSpinnerSizeLrg: 4.5rem;
$loadingSpinnerSizeFull: 6rem;

// progress spinner
.p-progress-spinner {
  height: $loadingSpinnerSizeMed;
  width: $loadingSpinnerSizeMed;
  margin: 1.5rem;

  circle.p-progress-spinner-circle {
    stroke-width: 0.25rem;
    animation-duration: 2s;
  }

  // modifiers
  &.clear {
    margin: 0;
  }

  &.is-small {
    height: $loadingSpinnerSizeSml;
    width: $loadingSpinnerSizeSml;
  }

  .p-datatable .actions-td &  {
    height: $loadingSpinnerSizeMed + (0.313rem * 2);
    width: $loadingSpinnerSizeMed + (0.313rem * 2);

    &.is-small {
      height: $loadingSpinnerSizeSml + (0.313rem * 2);
      width: $loadingSpinnerSizeSml + (0.313rem * 2);
    }
  }
}

// progress bar
.p-progressbar {
  height: 0.5rem;
  background-color: $primary-100;

  .p-progressbar-value {
    background-color: $primary-500;
  }

  // within table, modal or inlineDrawer
  .p-datatable &,
  .p-dialog.modal &,
  .inline-drawer &,
  .form & {
    height: 0.25rem;
    border-radius: 0;
  }
}

.loader .is-default {
  --start-colour:  #{$neutral-100};
  --end-colour: #{$neutral-300};
}
.loader .is-accented {
  --start-colour:  #{$primary-50};
  --end-colour: #{$primary-500};
}

@keyframes p-progress-spinner-color {
  from {
    stroke: var(--start-colour);
  }

  to {
    stroke: var(--end-colour);
  }
}