// material symbols base styles, use-case; replacing inaccessible primereact component icons.
@mixin mui {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: inherit;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// reference for all heading elements
h1, h2, h3,
h4, h5, h6 {
  @extend %headings !optional;
}

// Responsiveness
@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (min-width: $mobile), print {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (min-width: $mobile) and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin until-tablet {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin until-desktop {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @if $widescreen-enabled {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
      @content;
    }
  }
}

@mixin until-widescreen {
  @if $widescreen-enabled {
    @media screen and (max-width: $widescreen - 1px) {
      @content;
    }
  }
}

@mixin widescreen {
  @if $widescreen-enabled {
    @media screen and (min-width: $widescreen) {
      @content;
    }
  }
}

@mixin widescreen-only {
  @if $widescreen-enabled and $fullhd-enabled {
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
      @content;
    }
  }
}

@mixin until-fullhd {
  @if $fullhd-enabled {
    @media screen and (max-width: $fullhd - 1px) {
      @content;
    }
  }
}

@mixin fullhd {
  @if $fullhd-enabled {
    @media screen and (min-width: $fullhd) {
      @content;
    }
  }
}

@mixin fullhd-only {
  @if $fullhd-enabled and $ultrahd-enabled {
    @media screen and (min-width: $fullhd) and (max-width: $ultrahd - 1px) {
      @content;
    }
  }
}

@mixin until-ultrahd {
  @if $ultrahd-enabled {
    @media screen and (max-width: $ultrahd - 1px) {
      @content;
    }
  }
}

@mixin ultrahd {
  @if $ultrahd-enabled {
    @media screen and (min-width: $ultrahd) {
      @content;
    }
  }
}