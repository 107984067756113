$eventPaneMaxHeight: 80vh;
$eventPaneWidth: 450px;
$eventPaneToolbarHeight: 60px;
$eventPaneBaseHeight: 450px;

.calendar-popover.p-overlaypanel {
  min-height: $eventPaneBaseHeight;
  min-width: $eventPaneWidth;

  display: flex;
  align-items: center;
  justify-content: center;

  .p-overlaypanel-content {
    padding: 0;
  }

  .event-pane {
    max-height: $eventPaneMaxHeight;
    max-width: $eventPaneWidth;
    min-height: $eventPaneBaseHeight;
    width: $eventPaneWidth;

    > .p-toolbar {
      height: $eventPaneToolbarHeight;
      padding: 0.75rem 1rem;
      background-color: $neutral-100;

      .p-toolbar-group-left {
        margin-right: 3rem;
      }
    }

    &_content {
      min-height: $eventPaneBaseHeight - $eventPaneToolbarHeight;
      max-height: $eventPaneMaxHeight;
      overflow-y: auto;
      padding: 1rem;
      border: 1px solid $neutral-200;
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
      border-top: none;

      .event-info {
        .event-info_container {
          > *:nth-of-type(n + 2) {
            padding-top: 0.75rem;
          }
        }

        &_title {
          color: $neutral-400;
          padding-bottom: 0.5rem;

          &:not(:first-of-type) {
            margin-top: 1rem;
          }
        }

        .p-datatable {

          tr {
            td:first-of-type {
              padding-left: 0;
            }

             &:last-of-type {
               td {
                 border: none;
                 padding-bottom: 0;
               }
             }
          }

          .title-cell,
          .value-cell {
            font-size: 0.75rem;
            color: $secondaryTextColor;
          }

          .value-cell {
            color: $primaryTextColor;
            font-weight: 500;
          }
        }
      }
    }
  }
  //
  //// event loading/fetching elements
  //.event-spinner {
  //  height: 2.5rem;
  //  width: 2.5rem;
  //  margin: 1.5rem;
  //
  //  circle.p-progress-spinner-circle {
  //    stroke-width: 0.25rem;
  //    stroke: $primary-500;
  //    animation-duration: 2s;
  //  }
  //
  //  @keyframes p-progress-spinner-color {
  //    from {
  //      stroke: $primary-200;
  //    }
  //
  //    to {
  //      stroke: $primary-500;
  //    }
  //  }
  //}
}