.p-avatar {
  overflow: hidden;

  &-circle {
    border-radius: 1000px;
  }
  
  svg,
  .svg-image {
    width: 100%;
    height: 100%;
  }

  .p-avatar-text {
    font-weight: 500;
  }
}