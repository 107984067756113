$navHeight: 68px;
$sideNavWidthCollapsed: 66px;
$sideNavWidth: 275px;
$navBorderWidth: 1px;

// replace below value with var
$sideNavHeaderPadding: 0.5rem;

.side-nav {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 20;
  min-width: $sideNavWidth;
  background-color: var(--surface-0);
  border-right: $navBorderWidth solid var(--surface-border);
  transition: min-width $transitionDuration ease-in-out;

  &_head {
    display: flex;
    align-items: center;
    flex: none;
    position: relative;
    border-bottom: $navBorderWidth solid var(--surface-border);
    height: $navHeight;
    overflow: visible;
    padding: $sideNavHeaderPadding;

    .p-button.head_btn {
      justify-content: center;
      flex: 1;
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0.5rem 0;
    overflow-x: hidden;

    &-items {
      flex: 1;
    }

    &-support {
      flex: none;
    }

    &-items.p-tieredmenu,
    &-support.p-tieredmenu {
      width: unset;
      border: none;
      border-radius: 0;
      margin: 0;
      list-style: none;

      .p-menuitem {
        position: relative;
        background-color: transparent;
        transition: background-color $transitionDuration ease-in-out;
        color: $primaryTextColor;

        .p-menuitem-link,
        .p-menuitem-text,
        .p-menuitem-icon {
          color: inherit;
        }

        &.p-menuitem-active {
          background-color: $primary-50;

          .p-menuitem-text,
          .p-menuitem-icon {
            color: $primaryColor;
          }

          &:before {
            background-color: $primaryColor;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 3px;
          background-color: transparent;
          transition: background-color $transitionDuration ease-in-out;
        }

        &:hover {
          background-color: $primary-50;
        }

        .p-menuitem-link {
          background-color: transparent;

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
  }

  .side-nav_sub-head {
    display: flex;
    align-items: center;
    font-weight: 500;
    border-bottom: $navBorderWidth solid var(--surface-border);
    padding-bottom: 0.5rem;
  }


  &_foot {
    border-top: $navBorderWidth solid var(--surface-border);

    // use var
    padding: 0.5rem;

    // temp stying for bogus logo
    display: flex;
    align-items: center;

    img {
      max-height: 40px;
      margin-right: 0.5rem;
    }
  }

  .side-nav-toggle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    width: 20px;
    padding-left: 0;
    padding-right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;

    .material-symbols-outlined {
      color: $secondaryTextColor;
      transition: transform $transitionDuration ease-in-out;
    }
  }

  // collapse changes
  &.is-collapsed {
    min-width: $sideNavWidthCollapsed;

    .side-nav {

      &_head {
        .side-nav-toggle .material-symbols-outlined {
          transform: rotate(180deg);
        }

        .entity-switcher {

          .list-item {

            &_content {
              display: none;
            }

            .p-avatar {
              margin: 0;
            }
          }
          .material-symbols-outlined {
            display: none;
          }
        }

        .p-button.head_btn {
          .p-button-text {
            display: none;
          }

          .material-symbols-outlined {
            margin: 0;
          }
        }
      }

      &_body .p-menuitem {
        .p-menuitem-text {
          display: none;
        }

        .material-symbols-outlined {
          margin: 0 auto;
        }
      }

      &_foot {

        span {
          display: none;
        }

        img {
          margin: 0 auto;
        }
      }
    }
  }
}



// PUSH transition: enter from right (100%), existing panel moves off left.
.side-nav {
  @include react-transition-phase(
    "panel-stack",
    "enter",
    (transform: translateX(100%) translate(0%), opacity: 0 1),
    $easing: ease,
    $duration: 400ms
  );
  @include react-transition-phase(
    "panel-stack",
    "exit",
    (transform: translateX(-50%) translate(0%), opacity: 0 1),
    $easing: ease,
    $duration: 400ms
  );

  &-pop {
    @include react-transition-phase(
      "panel-stack",
      "enter",
      (transform: translateX(-50%) translate(0%), opacity: 0 1),
      $easing: ease,
      $duration: 400ms,
    );
    @include react-transition-phase(
      "panel-stack",
      "exit",
      (transform: translateX(100%) translate(0%), opacity: 0 1),
      $easing: ease,
      $duration: 400ms,
    );
  }
}