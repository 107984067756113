.page-header {
  margin-bottom: 1.25rem;

  &_middle {
    display: flex;
    align-items: flex-end;

    &--left,
    &--right {
      display: flex;
    }

    &--left {
      flex-direction: column;
      flex: 1;

      .page-header_title {
        display: flex;
        align-items: center;

        .icon.material-symbols-outlined {
          font-size: 32px;
          padding-right: 0.25rem;
        }
      }

      p.page-header_desc {
        padding-top: 0.5rem;
      }
    }

    &--right {
      align-self: flex-end;
      margin-left: 2rem;

      .p-button {
        margin-left: 0.625rem;
      }
    }
  }

  &_bottom {
    margin-top: 1rem;
  }

  // conditional styles:
  // no tab component (_bottom) adds spacing and border below.
  &:not(.has-tabs) {
    padding-bottom: 1rem; // temp, replace w/ var
    border-bottom: 1px solid #dee2e6; // temp, replace w/ mixin
  }

  &_middle--left:not(.has-desc) ~ .page-header_middle--right {
    align-self: center;
  }
}

.game-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &_details {
    padding: 20px;
  }

  &_team {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
    
    &--opponent {
      .game-header_team-details {
        justify-content: end;
      }
    }
  }

  &_team-details {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &_score {
    font-size: 32px;
    font-weight: bold;
  }

  &_team-name {
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
  }
}