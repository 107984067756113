$topNavPadding: 0.5rem;
$topNavClearPadding: 20px;

.top-nav {
  position: sticky;
  top: 0;
  flex: none;
  height: $navHeight;
  background-color: var(--surface-0);
  border: none;
  border-radius: 0;
  border-bottom: $navBorderWidth solid var(--surface-border);
  z-index: 10;
  padding-left: calc(#{$topNavClearPadding} + #{$topNavPadding});

  &.p-menubar {
    > .p-menubar-root-list {
      margin-left: auto;

      .p-menuitem {
        &-link {
          padding: 0.5rem 1rem;
        }

        i.material-symbols-outlined {
          font-size: 1.25rem;
          margin: 0;
        }
      }
    }

    .p-menubar-end {
      margin: 0;

      .user-menu {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        cursor: pointer;
        overflow: hidden; // prevents gapi error displaying broken image text outside boundaries.

        &_text {
          padding-right: 0.5rem;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }

      .p-menubar-root-list {
        position: relative;
        padding-left: 0.5rem;
        margin-left: 0.5rem;

        &:before {
          content: '';
          position: absolute;
          height: 70%;
          width: 1px;
          background-color: $neutral-200;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}


.product-grid {
  color: $neutral-800;
  width: 328px;
  padding: 20px 20px;
  border-radius: 8px;

  &_list {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
  }

  &_item {
    position: relative;
    display: inline-block;
    list-style-type: none;
    padding: 6px;
    vertical-align: top;
  }

  &_link {
    display: block;
    width: 84px;
    height: 84px;
    margin: 0;
    padding-top: 4px;
    border-radius: 12px;
    text-align: center;
    text-decoration: none;
    outline: none;

    &:hover {
      background-color: $primary-50;
      outline: none;
    }
  }

  &_title {
    color: $neutral-800;
    display: inline-block;
    font-size: 14px;
    letter-spacing: .09px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 76px;
    font-weight: 600;
  }
}