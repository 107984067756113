/** RMDS Color **/

// Neutral
$neutral-0: #ffffff;
$neutral-50: #F8F8F9;
$neutral-100: #F1F1F2;
$neutral-200: #E3E3E4;
$neutral-300: #C8C8C9;
$neutral-400: #939394;
$neutral-500: #656567;
$neutral-600: #4A4A4D;
$neutral-700: #2F2F32;
$neutral-800: #1D1D20;
$neutral-900: #161618;
$neutral-1000: #000000;

// Primary
$primary-50: #FEF1E6;
$primary-100: #FDE2CC;
$primary-200: #FBC699;
$primary-300: #F99B4D;
$primary-400: #F77E19;
$primary-500: #FF6700;
$primary-600: #AC4E00;
$primary-700: #873E00;
$primary-800: #622D00;
$primary-900: #3E1C00;

// Blue
$blue-50: #E6F3FE;
$blue-100: #CCE7FD;
$blue-200: #99CFFB;
$blue-300: #4DAAF9;
$blue-400: #0086F6;
$blue-500: #0072D1;
$blue-600: #005EAC;
$blue-700: #004A87;
$blue-800: #003662;
$blue-900: #00223E;

// Green
$green-50: #E7F8F2;
$green-100: #D1FAE5;
$green-200: #A7F3D0;
$green-300: #6EE7B7;
$green-400: #34D399;
$green-500: #10B981;
$green-600: #059669;
$green-700: #047857;
$green-800: #065F46;
$green-900: #064E3B;

// Orange
$orange-50: #FFF7E6;
$orange-100: #FFEECC;
$orange-200: #FFDD99;
$orange-300: #FFC44D;
$orange-400: #FFB319;
$orange-500: #F2A200;
$orange-600: #D99100;
$orange-700: #B37800;
$orange-800: #8C5E00;
$orange-900: #664400;

// Red
$red-50: #FBE6E8;
$red-100: #F8CCD2;
$red-200: #F199A5;
$red-300: #E64D61;
$red-400: #E0263F;
$red-500: #DB001D;
$red-600: #BA0019;
$red-700: #990014;
$red-800: #780010;
$red-900: #58000C;

// Shades
$shade-0: #FFFFFF;
$shade-100: #000000;


/** Intents **/

// Primary
$primaryColor: $primary-500;
$primaryDarkColor: $primary-600;
$primaryDarkerColor: $primary-700;

// Info
$infoColor: $blue-500;
$infoDarkColor: $blue-600;
$infoDarkerColor: $blue-700;

// Success
$successColor: $green-500;
$successDarkColor: $green-600;
$successDarkerColor: $green-700;

// Warning
$warningColor: $orange-500;
$warningDarkColor: $orange-600;
$warningDarkerColor: $orange-700;

// Help
$helpColor: $blue-200;
$helpDarkColor: $blue-300;
$helpDarkerColor: $blue-400;

// Danger
$dangerColor: $red-500;
$dangerDarkColor: $red-600;
$dangerDarkerColor: $red-700;


/** Borders **/
$border-radius-sm: 0.25rem; // 4px
$border-radius-md: 0.375rem; // 6px
$border-radius-lg: 0.5rem; // 8px

$borderInsetBtm: inset 0px -1.5px 0px #CBD5E1;

/** Elevation **/
$elevation-0: 0 0 0 rgba(0, 0, 0, 0) !default;
$elevation-1: 0 0 0 1px rgba($neutral-1000, 0.1) !default;
$elevation-2: 0 0.25em 0.5em -0.125em rgba($neutral-1000, 0.1), 0 0 0 1px rgba($neutral-1000, 0.05) !default;
$elevation-3: 0 0.5em 1em -0.125em rgba($neutral-1000, 0.1), 0 0 0 1px rgba($neutral-1000, 0.05) !default;


// Typography
$family-primary: "Inter", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

// type tracking
$typeTrackingSml: 0.025rem;
$typeTrackingStd: 0.08rem;
$typeTrackingLrg: 0.16rem;

// primary text / std / dark / darker
// secondary text / std / dark / darker
$primaryTextColor: $neutral-700;
$secondaryTextColor: $neutral-500;
$disabledTextColor: $neutral-300;


// button text colours
$buttonTextColor: $neutral-100;
$buttonTextHoverColor: $neutral-50;
$buttonTextActiveColor: $neutral-0;

$buttonTextColorDark: $primaryTextColor;
$buttonTextHoverColorDark: $neutral-800;
$buttonTextActiveColorDark: $neutral-1000;

// misc
$borderColor: $neutral-50;

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
    --surface-a: #ffffff;
    --surface-b: #f8f9fa;
    --surface-c: #e9ecef;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #495057;
    --text-color-secondary: #6c757d;
    --primary-color: #2196F3;
    --primary-color-text: #ffffff;
    --font-family: #{$family-primary};
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};
    --surface-ground: #f8f9fa;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #e9ecef;
    --maskbg: #{$maskBg};
    --focus-ring: #{$focusShadow};
    --color-scheme: light;
}