// type helpers
.rm-theme {
  .title.is-bold {
    font-weight: 900;
  }
}

// expand upon
%headings, p {
  font-family: $family-primary;
  color: $primaryTextColor;
}