.list {
  display: flex;
  flex-direction: column;
  border: 1px solid $neutral-200;
  border-radius: $border-radius-lg;

  & > .list-item,
  & > div {
    padding: 0.75rem 1.5rem;

    &:nth-of-type(n + 2) {
      border-top: 1px solid $neutral-300;
    }
  }
}