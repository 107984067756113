@import './variables';
@import './_fonts';
@import '../prime/_components';
//@import './_extensions';

// fix for prime divider, css hierarchy issue in applying style of solid to border without theme import.
.p-divider.p-divider-vertical.p-divider-solid:before {
  border-left-style: solid;
}

// fix prime disabled button style
// prime stylesheet use important tag to remove pointer-events as well as setting cursor to auto when button disabled
.rm-theme .p-button.p-disabled,
.rm-theme .p-button.p-disabled *,
.rm-theme .p-button:disabled,
.rm-theme .p-button.p-disabled:disabled *,
.rm-theme .p-disabled,
.rm-theme .p-disabled *,
.rm-theme *:disabled,
.rm-theme .p-disabled:disabled * {
  cursor: not-allowed !important;
}