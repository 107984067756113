// Configuration for the font-face of the theme, defaults to the system font so left as blank

// temp remove margin from h tags.
// TODO: create type variables/mixins.
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

// implement default font-family/-size onto all elements.
html {
  font-family: $fontFamily;
  font-size: $fontSize;
}