// hides LP injecting into dom after load and breaking onClicks.
[data-lastpass-root] {
  display: none;
}

body {
  margin: 0;
}

// move to another file to handle all app related style?
.app-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: $neutral-50; // RMDS Surface-50 ? neutral
  overflow: auto;
}
