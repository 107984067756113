$drawerWidth: 450px;
$drawerContentPadding: 1rem;
$drawerEndRegionPadding: 0.75rem 1rem;

.inline-drawer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: sticky;
  top: 0;
  height: calc(100vh - ($navHeight + ($pageInnerPadding * 2)));
  max-width: $drawerWidth;
  background-color: $neutral-0;
  border-radius: $border-radius-sm;
  margin-left: 1.25rem;
  box-shadow: $elevation-3;
  overflow-y: auto;
  overflow-x: hidden;

  &_header {
    position: sticky;
    padding: $drawerEndRegionPadding;
    background-color: $neutral-0;
    border-bottom: 1px solid $neutral-200;
    top: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    &-title {
      flex-grow: 1;
    }

    &-actions {
      display: flex;
      align-items: center;
      align-self: flex-start;
      flex-shrink: 0;
    }
  }

  &_content {
    flex: 1;
    padding: $drawerContentPadding;
  }

  &_footer {
    padding: $drawerEndRegionPadding;
    border-top: 1px solid $neutral-200;

    .p-buttonset {
      display: flex;
      margin: 0 -0.25rem;

      button.p-button {
        flex: 1;
        justify-content: center;

        .p-button-label {
          flex: none;
        }
      }
    }
  }

  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $neutral-100;
    z-index: 998; 
  }

  // fixed footer modifier
  &.has-fixed-footer {
    .inline-drawer_footer {
      background-color: $shade-0;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.is-maximized {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    width: 800px;
    max-width: none;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}