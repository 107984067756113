.rm-theme {
  .p-overlaypanel.p-component {
    border-radius: $border-radius-lg;
    overflow: hidden;

    //.p-overlaypanel-content {
    //  border-bottom-left-radius: $border-radius-lg;
    //  border-bottom-right-radius: $border-radius-lg;
    //}
  }
}