.p-toast {
  .p-toast-message {
    background-color: $shade-100;
    color: $shade-0;

    .p-toast-message-content {
      .p-toast-message-icon {
        display: none;
        position: relative;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;

        .p-toast-message-success &,
        .p-toast-message-info &,
        .p-toast-message-warn &,
        .p-toast-message-error & {
          display: block;
        }

        &:after {
          content: "home";
          position: absolute;
          font-family: "Material Icons";
          font-size: 1.25rem;
          color: $buttonTextColor;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: color $transitionDuration ease-in-out;
        }

        // success
        &.pi-check:after {
          content: "check_circle";
        }

        // warn
        &.pi-exclamation-triangle:after {
          content: "warning";
        }

        // danger
        &.pi-times:after {
          content: "error";
        }

        // info
        &.pi-info-circle:after {
          content: "info";
        }
      }

      .p-toast-message-text {
        margin: 0;

        .p-toast-detail {
          > button.p-button,
          > .button-group {
            margin-top: 0.75rem;
          }

          .button-group button.p-button:nth-of-type(n + 2) {
            margin-left: 0.5rem;
          }
        }
      }
    }

    // toast content row flexed
    &.p-toast-message--is-inline .p-toast-message-content {
      align-items: center;

      .p-toast-message-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 0.75rem;
      }

      .p-toast-detail {
        margin: 0 0 0 1.5rem;
        padding-left: 1rem;
        border-left: 1px solid $borderColor;

        button.toast-action {
          margin-top: 0;
        }
      }
    }

    // replace prime icon w/ material -- close
    .p-toast-icon-close {
      position: relative;
      width: 1.75rem;
      height: 1.75rem;

      span:after {
        content: "close";
        position: absolute;
        font-family: "Material Icons";
        font-size: 1.25rem;
        color: $buttonTextColor;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: color $transitionDuration ease-in-out;
      }
    }

    // show icon for all severity types except neutral.
    &.p-toast-message-success,
    &.p-toast-message-info,
    &.p-toast-message-warn,
    &.p-toast-message-error {
      .p-toast-message-icon {
        display: block;
      }
    }
  }
}