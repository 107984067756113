$smCellPadding: 0.75rem 1.5rem;
$tdCellPadding: 1rem 1.5rem;
$editableColMinWidth: 225px;

.p-datatable {
  border: 1px solid $neutral-200;
  border-radius: $border-radius-lg;
  overflow: hidden;

  .p-datatable-header {
    padding: 0;
  }

  // striped rows
  &.p-datatable-striped table.p-datatable-table {
    tbody.p-datatable-tbody > tr.p-row-odd {
      background-color: $neutral-50;
    }
  }

  // selectable rows
  &.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row {
    background: $shade-0;
    transition: background-color $transitionDuration ease-in-out;

    &:not(.p-highlight):not(.p-datatable-emptymessage):hover {
      background-color: $neutral-50;

      &.p-row-odd .p-datatable-striped & {
        background-color: $neutral-100;
      }
    }
  }

  // main table styles
  table.p-datatable-table {
    thead.p-datatable-thead {
      > tr {
        background-color: $neutral-100;

        > th {
          color: $neutral-600;
          font-weight: 600;
          font-size: 0.75rem;
          text-transform: uppercase;
          letter-spacing: $typeTrackingStd;
          padding: $smCellPadding;
          background-color: $neutral-100;
          box-shadow: inset 0px -1px 0px #CBD5E1;

          .p-column-title {
            white-space: nowrap;
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      > tr {
        > td {
          padding: $tdCellPadding;
          height: 71px; // temp solution, assist keeping rows changing height with inner elements.
          white-space: nowrap;

          a.td-link {
            color: $primaryTextColor;
            border-bottom: 1px solid transparent;
            transition: border-color $transitionDuration ease-in-out, color $transitionDuration ease-in-out;

            &:hover {
              color: $primary-500;
              border-color: $primary-500;
            }
          }

          button.td-action {
            padding: 0;
          }

          a.td-wrapper-link {
            display: block;
            max-width: fit-content;
            max-width: -moz-fit-content;

            &:hover {
              > h1, h2, h3, h4, h5, h6 {
                color: $textSecondaryColor;
              }
            }
          }

          &.p-cell-editing {
            padding: 0.688rem 1.5rem;
          }

          // forced width onto any editable column
          &.p-editable-column > * {
            max-width: $editableColMinWidth;
            width: 100%;
          }
        }
      }
    }

    // action related cells
    th.actions-td,
    td.actions-td {
      text-align: right;

      .actions-td_wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .p-buttonset {
          margin-left: 1rem;
        }
      }

      .p-tag.action-status {
        margin: 0.375rem 0;
      }
    }
  }

  .p-datatable-footer {
    display: flex;
    justify-content: space-between;
    background-color: $neutral-100;
    padding: 0.5rem;
    box-shadow: inset 0px 1px 0px #CBD5E1;

    > button.p-button {
      &:last-of-type {
        margin-left: auto;
      }

      &:not(:last-of-type):nth-of-type(n + 2) {
        margin-left: 0.5rem;
      }
    }
  }

  // table with progressBar component as loader
  .has-loadingbar {
    position: relative;

    .p-progressbar {
      position: absolute;
      bottom: -0.125rem;
      left: 0;
      right: 0;
    }
  }

  // file upload style within error state component when in table.
  &.empty-message--has-uploader table.p-datatable-table .p-datatable-emptymessage {
    > td {
      padding: 1rem 1rem;
    }

    .p-fileupload-advanced {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      padding: 1rem 1rem;

      .p-fileupload-buttonbar {
        position: absolute;
        align-self: center;
        bottom: 3rem;
        z-index: 1;
        padding: 0;
        background: transparent;
        border: none;

        .p-button {
          margin: 0;
        }
      }

      .p-fileupload-content {
        border: none;
        padding: 1rem 0 5rem 0;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E3E3E400' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
        transition: background-image 100ms ease-in-out;

        &.p-fileupload-highlight {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E3E3E4FF' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
        }
      }
    }
  }
}

.detail-cell > * {
  margin-right: 8px;
  vertical-align: middle;
}

.table-disclaimer {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0 0.75rem;
  color: $neutral-500;

  img,
  span {
    vertical-align: top;
  }
}

.datatable-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}