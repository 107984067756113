.p-breadcrumb.p-component {
  display: inline-flex;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.75rem;

  // home crumb
  .p-breadcrumb-home .p-menuitem-link,
  .p-breadcrumbs-consolidated {
    cursor: pointer;
  }

  // crumb divider
  span.icon.crumb-divider {
    padding: 0 4px;
    cursor: default;
  }

  // color for all but current
  .p-breadcrumbs-consolidated .p-menuitem-link .icon,
  .p-breadcrumb-home .p-menuitem-link .icon,
  .p-menuitem-link span.p-menuitem-text,
  span.icon.crumb-divider {
    color: $textSecondaryColor;

    &:not(.crumb-divider):hover {
      color: $textColor;
    }
  }

  // current crumb item
  .p-menuitem-link.current-crumb {
    cursor: default;

    span.p-menuitem-text,
    span.p-menuitem-text:hover {
      color: $textColor;
      font-weight: 500;
    }
  }

  ul li.p-breadcrumb-chevron:before {
    content: "/";
  }

  // modifiers
  &.has-small-crumbs {
    li a.p-menuitem-link span.p-menuitem-text {
      font-size: 0.75rem;
    }
  }
}