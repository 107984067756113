.form-step {
  display: flex;
  flex-direction: column;

  input {
    z-index: 1; // set all inputs to sit above floated error messages.
  }

  .p-component.p-invalid {
    border-color: $dangerDarkColor;
  }

  // labels
  &_label {
    font-size: 0.875rem; // 14px
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0.313rem; // 5px
  }

  // help text
  &_help-text {
    font-size: 0.75rem; // 12px
    margin-top: 0.219rem; // 3.5px
  }
}