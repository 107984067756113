$borderRadiusStd: 3px;
$borderRadiusFull: 50%;

.rm-theme {

  // over-ride always removing border-radius when using button-set and wanting spaced elements within
  .p-buttonset {
    &.set-is-spaced .p-button {
      &:first-of-type {
        border-top-right-radius: $borderRadiusStd;
        border-bottom-right-radius: $borderRadiusStd;
      }

      &:last-of-type {
        border-top-left-radius: $borderRadiusStd;
        border-bottom-left-radius: $borderRadiusStd;
      }

      &:not(:first-of-type),
      &:not(:last-of-type) {
        border-radius: $borderRadiusStd;
      }
    }

    &.set-is-stacked {
      flex-direction: column;

      > .p-button {
        justify-content: center;
        border-radius: $borderRadiusStd;

        &:not(:last-child) {
          border-right: 1px solid #E3E3E4; // apply var later, for now don't use modifier without secondary severity actions.
        }

        .p-button-label {
          flex: none;
        }

        &:nth-of-type(n + 2) {
          margin-top: 0.5rem;
        }
      }
    }

    &:nth-of-type(n + 2) {
      margin-left: 0.5rem;
    }

    &.p-button-rounded {
      border-radius: $borderRadiusFull;
    }
  }

  .button,
  .p-button {

    // text only modifier class
    &.text-only {
      display: flex;

      .button-text {
        text-decoration: underline;
        transition: color $transitionDuration ease-in-out;
      }

      &:hover {
        .button-text {
          color: $primaryColor;
        }
      }
    }

    // secondary button override for text-only secondary button
    &.p-button-secondary.p-button-text:not(.ui-button) {
      color: $neutral-900;

      &:hover {
        color: $neutral-500;
      }
    }

    // secondary button override for + outlined
    &.p-button-secondary.p-button-outlined {
      color: $neutral-300;
      border-color: $neutral-300;
      background-color: transparent;
      transition:
        color $transitionDuration ease-in-out,
        border-color $transitionDuration ease-in-out,
        background-color $transitionDuration ease-in-out
      ;

      &:hover {
        color: $neutral-400;
        border-color: $neutral-400;
        background-color: $neutral-50;
      }
    }

    &.ui-button {
      color: $neutral-400;
      padding: 0.375rem;

      &:enabled:hover {
        color: $neutral-300;
      }
    }

    &.is-full-width {}
  }

  // fix missing icon in dropdown button
  .p-splitbutton {
    > .p-splitbutton-menubutton {
      position: relative;

      .p-button-icon.pi-chevron-down {
        &:after {
          content: "expand_more";
          position: absolute;
          font-family: "Material Icons";
          font-size: 1.5rem;
          color: $secondaryTextColor;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: content $transitionDuration ease-in-out;
        }
      }

      &[aria-expanded='true'] {
        .p-button-icon.pi-chevron-down:after {
          content: "expand_less";
        }
      }
    }
  }

  // remove underline from all a tags.
  a {
    text-decoration: none;
  }
}