// values for size types
$sizeSmall: (
  'wrapWidth': unset,
  'wrapHeight': unset,
  'elWidth': $loadingSpinnerSizeSml,
  'elHeight': $loadingSpinnerSizeSml,
);
$sizeMedium: (
  'wrapWidth': unset,
  'wrapHeight': unset,
  'elWidth': $loadingSpinnerSizeMed,
  'elHeight': $loadingSpinnerSizeMed,
);
$sizeLarge: (
  'wrapWidth': unset,
  'wrapHeight': 6rem,
  'elWidth': $loadingSpinnerSizeLrg,
  'elHeight': $loadingSpinnerSizeLrg,
);
$sizeFull: (
  'wrapWidth': calc(100vw - ($pageInnerPadding * 2)),
  'wrapHeight': calc(100vh - ($navHeight + ($pageInnerPadding * 2))),
  'elWidth': $loadingSpinnerSizeFull,
  'elHeight': $loadingSpinnerSizeFull,
);

// values for weight types
$lightWeight: ('strokeWeight': .5);
$mediumWeight: ('strokeWeight': 2);
$heavyWeight: ('strokeWeight': 3);

// group all into obj/s to be mapped
$sizeOptions: (
  'small': $sizeSmall,
  'medium': $sizeMedium,
  'large': $sizeLarge,
  'fullscreen': $sizeFull,
);
$weightOptions: (
  'light-weight': $lightWeight,
  'medium-weight': $mediumWeight,
  'heavy-weight': $heavyWeight,
);

// loader wrapper
.loader {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @each $key, $val in $sizeOptions {
      &.is-#{$key} {
        height: map-get($val, wrapHeight);
        width: map-get($val, wrapWidth);
      }
    }

    @each $key, $val in $weightOptions {
      &[data-weight="#{$key}"] svg .p-progress-spinner-circle {
        stroke-width: map-get($val, strokeWeight);
      }
    }
  }
}

// wrapped spinner
.loader-element {
  @each $key, $val in $sizeOptions {
    .loader.is-#{$key} & {
      height: map-get($val, elHeight);
      width: map-get($val, elWidth);
    }
  }

  &.squared svg .p-progress-spinner-circle {
    stroke-linecap: square;
  }
}