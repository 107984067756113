$overlayMaxWidth: calc(#{$sideNavWidth} - #{$navBorderWidth} - (#{$sideNavHeaderPadding} * 2));
$overlayContentMaxHeight: 450px;
$overlayContentEllipsisWidth: 160px;

.entity-switcher {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
  background-color: var(--surface-50);
  border: 1px solid var(--surface-border);
  padding: $sideNavHeaderPadding;
  box-shadow: transparent;
  cursor: pointer;
  border-radius: $border-radius-sm;

  &:hover {
    background-color: var(--surface-hover);
    box-shadow: var(--shadow-1);

    .material-symbols-outlined {
      color: $primaryColor;
    }
  }
}

// entity OverlayPanel
.entity-popover {
  width: $overlayMaxWidth;
  border: 1px solid $neutral-200;

  .p-submenu-header {
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 700;
    color: $textSecondaryColor;
    letter-spacing: $typeTrackingLrg;
    padding: 0.5rem 1rem 0.25rem 1rem;

    &:not(:first-child) {
      border-top: 1px solid $neutral-200;
      padding-top: 0.75rem;
    }
  }

  .p-overlaypanel-content {
    padding: 0;
    border-radius: 6px;
    overflow: hidden;

    .p-menu {
      width: unset;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $neutral-200;
      max-height: $overlayContentMaxHeight;
      overflow-y: auto;
    }

    .list-item .list-item_content-title {
      width: $overlayContentEllipsisWidth;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .entity-view-action {
      > button {
        flex: 1;
        justify-content: center;
      }

      &:hover {
        background: transparent;
      }
    }

    .entity-popover_footer {
      padding: 0.5rem;
      margin: 0;
      background-color: rgba($neutral-50, 0.5);
    }
  }
}

// list-item style, specific to within ES
#entity_switcher .p-menuitem:hover {
  .material-symbols-outlined {
    opacity: 1;
  }
}
