$toolbarRadius: $border-radius-lg;

.rm-theme .p-toolbar {
  background-color: $neutral-200;
  padding: 0.5rem;
  border-radius: 0;
  border-top-left-radius: $toolbarRadius;
  border-top-right-radius: $toolbarRadius;

  .p-toolbar-group-left {
    & > :nth-child(n + 2) {
      padding-left: 0.5rem;
    }

    .toolbar-title {
      color: $primaryTextColor;
      letter-spacing: $typeTrackingStd;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .p-toolbar-group-right {
    & > :nth-last-child(n+2) {
      margin-right: 0.5rem;
    }

    .p-button.toolbar-action {
      width: unset;
    }
  }
}

.p-toolbar .p-dropdown {
  height: 40px;

  .p-dropdown-label {
    min-height: 40px;
  }
}

