$pageInnerPadding: 1.25rem;

.page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &_inner {
    height: 100%;
    padding: $pageInnerPadding;
    overflow-y: auto;
    max-height: calc(100vh - #{$navHeight});
    transition: max-width $transitionDuration ease-in-out;
  }

  .side-nav ~ & {
    .page-wrapper_inner {
      max-width: calc(100vw - (#{$sideNavWidth} + #{$navBorderWidth}));
    }
  }

  .side-nav.is-collapsed ~ & {
    .page-wrapper_inner {
      max-width: calc(100vw - (#{$sideNavWidthCollapsed} + #{$navBorderWidth}));
    }
  }
}