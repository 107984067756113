$minInputHeight: 48px;
$inputPadding: 0.75rem;

input,
input.p-input,
.form-segment > input {
  min-height: $minInputHeight;
  padding: $inputPadding;

  &[type=date] {
    cursor: pointer;
  }

  &.p-disabled,
  &.p-component:disabled {
    opacity: 0.5;
  }
}

.p-dropdown {
  .p-dropdown-label {
    display: flex;
    align-items: center;
    min-height: $minInputHeight;
    padding: $inputPadding;
    padding-right: $inputPadding * 2;
  }

  i.p-dropdown-clear-icon {
    display: none;
  }

  .p-dropdown-trigger {
    &:after {
      content: "expand_more";
      font-family: "Material Icons";
      font-size: 1.5rem;
      color: $secondaryTextColor;
      transform: rotate(0deg);
      transition: transform $transitionDuration ease-in-out, color $transitionDuration ease-in-out;
    }

    &[aria-expanded="true"]:after {
      transform: rotate(180deg);
    }

    &:hover:after {
      color: $primaryTextColor;
    }
  }
}

// radio button - colour pick
.field-radiobutton.colour-pick-radio {
  display: flex;
  align-items: center;

  .p-radiobutton {
    width: unset;
    height: unset;

    .p-radiobutton-box {
      background: var(--colour-option);
      border: 1px solid $neutral-200;
      border-radius: $border-radius-md;
      height: 2.25rem;
      width: 2.25rem;
      box-shadow: $elevation-0;

      &:hover {
        background: var(--colour-option);
        border-color: $neutral-300;
        box-shadow: $elevation-2;
      }

      &.p-highlight {
        border-width: 2px;
        border-color: $primary-500;
        box-shadow: $elevation-2;
      }

      .p-radiobutton-icon {
        display: none;
      }
    }
  }

  label {
    display: none;
  }
}

// fix prime calendar ui icons
.p-datepicker {
  button.p-datepicker-prev,
  button.p-datepicker-next {
    position: relative;

    // configure pseudo element to replace PI
    span.p-datepicker-next-icon:after,
    span.p-datepicker-prev-icon:after {
      position: absolute;
      font-family: "Material Icons";
      font-size: 1.5rem;
      color: $secondaryTextColor;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: color $transitionDuration ease-in-out;
    }

    // prev icon name
    span.p-datepicker-prev-icon:after {
      content: "chevron_left";
    }

    // next icon name
    span.p-datepicker-next-icon:after {
      content: "chevron_right";
    }

    // hover style
    span.p-datepicker-prev-icon:hover:after {
      color: $primaryTextColor;
    }

    // disabled style
    &.p-disabled {
      span.p-datepicker-next-icon:after,
      span.p-datepicker-prev-icon:after {
        color: $disabledTextColor;
      }
    }
  }

  // fix disabled display
  .p-yearpicker,
  .p-datepicker-calendar {
    tbody tr td span.p-disabled,
    .p-yearpicker-year.p-disabled {
      opacity: 0.35;
    }
  }
}

// select button groups within form wrappers.
.form .p-selectbutton.p-buttonset {
  flex: 1;
  z-index: 1;

  > .p-button {
    flex: 1;
    min-height: $minInputHeight;
  }
}