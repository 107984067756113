$transition: all 300ms ease-in-out; // to be replaced

.form-stepper {

  &_content {
    padding: 1.25rem;

    .form-step {
      &:nth-of-type(n + 2) {
        padding-top: 0.75rem;
      }
    }
  }

  .p-steps {
    .p-steps-item {
      .p-menuitem-link {
        border-bottom: 3px solid $neutral-200;
        transition: $transition;
        cursor: default;
      }

      // active step
      &.p-steps-current {
        .p-menuitem-link {
          border-bottom-color: $primaryColor;
        }

        .p-avatar {
          background-color: $primary-50;
          border: 2px solid $primaryColor;

          .p-avatar-text {
            color: $primaryColor;
            transition: $transition;
          }
        }
      }

      .severity-success,
      &.p-steps-current .severity-success {
        border-bottom-color: $successColor;

        .p-avatar {
          border-color: $successColor;
          background-color: $successColor;

          .p-avatar-text {
            color: $successColor;
          }
        }
      }

      // validated icon
      .severity-success .list-item .list-item_inner {
        .icon {
          margin: 0;
          color: $neutral-0;
        }
      }

      &:nth-of-type(n + 2) {
        border-left: 1px solid $neutral-200;
      }

      .p-avatar {
        background-color: $neutral-50;
        border: 2px solid $neutral-300;
        transition: $transition;
      }

      .p-menuitem-link {
        flex: 1;
        flex-direction: row;
        padding: 8px 12px;
        align-items: center;
        border-radius: 0;

        .p-steps-title {
          margin-top: 0;
          padding-left: 6px;
        }
      }

      &:before {
        content: none;
      }
    }
  }
}