$eventCellSpacing: 0.25rem;
$chipNotchOffset: 0.25rem;

$genericEvent: (
  'color-default': $blue-400,
  'color-dark': $blue-600,
  'color-light': $blue-200,
);

$gameEvent: (
  'color-default': $green-400,
  'color-dark': $green-600,
  'color-light': $green-200,
);

$testingEvent: (
  'color-default': $orange-400,
  'color-dark': $orange-600,
  'color-light': $orange-200,
);

$trainingEvent: (
  'color-default': $red-400,
  'color-dark': $red-600,
  'color-light': $red-200,
);

$eventTypes: (
  'generic-event': $genericEvent,
  'game-event': $gameEvent,
  'testing-event': $testingEvent,
  'training-event': $trainingEvent,
);

.rbc-row {
  min-height: 30px; // force rbc to calculate event height based on this value.
}

.modal.truncated-events,
.calendar-wrapper {
  .rbc-row-segment,
  .rbc-events-container {
    padding: 0 $eventCellSpacing 0 0;

    &:nth-of-type(n + 2) {
      padding-top: $eventCellSpacing;
    }
  }

  // modal specific rbc-event item spacing
  .truncated-events-wrapper {
    .rbc-event:nth-of-type(n + 2) {
      margin-top: 0.5rem;
    }
  }

  .event-item_wrapper {
    // mixed usage event styles, inside calendar only event specific below > .calendar-wrapper {}
    &.rbc-event {
      padding: 0;
      background-color: unset;
      border-radius: $border-radius-sm;
      box-shadow: $elevation-0;
      border: none;
      transition: box-shadow $transitionDuration ease-in-out;

      .rbc-event-content {
        padding: 0.25rem 0.375rem;
      }

      &:hover {
        box-shadow: $elevation-2;
      }

      // over-ride rbc
      &:focus {
        outline: none;
      }

      &.rbc-selected {
        background-color: unset;
      }

      // event list item
      .event-calendar_chip {
        margin-left: $chipNotchOffset;

        .list-item_content-title > * {
          font-size: 0.75rem;
          white-space: nowrap;
        }

        .list-item_inner {
          .icon {
            font-size: 1.25rem;
            color: $secondaryTextColor;
            margin-right: 0.375rem;
          }
        }

        .list-item_content-subline {
          color: $primaryTextColor;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 750px;

          &:first-letter {
            text-transform: capitalize;
          }
        }
      }

      &-content {
        display: flex;
        align-items: center;
      }

      // hide unwanted rbc element
      .rbc-event-label {
        display: none;
      }
    }

    @each $key, $val in $eventTypes {
      &.#{$key}.rbc-event {
        border-color: map-get($val, color-dark);
        background-color: map-get($val, color-light);
        &:before {
          background-color: map-get($val, color-dark);
        }
        &.rbc-selected {
          background-color: map-get($val, color-default);
        }
        .event-calendar_chip .list-item_inner .icon {
          color: map-get($val, color-dark);
        }
      }
    }
  }
}

// event styling specific to events rendered inside calendar directly.
.rbc-event {
  .rbc-row-segment &,
  .rbc-events-container &,
  .rbc-agenda-event-cell & {
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .event-calendar_chip .list-item_content-title > * {
      text-transform: uppercase;
    }

    .event-calendar_chip .list-item_content-subline {
      font-size: 0.75rem;
      white-space: nowrap;
    }

    &:before {
      content: '';
      width: $chipNotchOffset;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
}

