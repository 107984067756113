body.rm-theme .is-clickable {
  cursor: pointer;
}

.is-transparent {
  opacity: 0;
}

.is-underlined {
  text-decoration: underline;
}

.pointer-events-none {
  pointer-events: none;
}