$listItemCardHorizontalPadding: 1rem;
$listItemCardVerticalPadding: 0.75rem;

.list-item {
  flex: 1;

  .list-item_inner {
    display: flex;
    align-items: center;

    >:not(.p-avatar) .icon {
      margin-right: 0.5rem;
    }
  }

  &.p-card {
    .list-item_inner {
      padding: $listItemCardVerticalPadding $listItemCardHorizontalPadding;
    }
  }

  &_content {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-title {
      > * {
        margin: 0;
        font-weight: 700;
        color: $primaryTextColor;
        font-size: .875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        transition: color $transitionDuration ease-in-out;
      }
    }

    &-subline {
      color: $secondaryTextColor;
      font-size: .75rem;
      font-weight: 500;

      &.caption-sm {
        font-size: .625rem;
      }

      &.caption-lg {
        font-size: .875rem;
      }
    }
  }

  &_actions {
    .p-card & {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: -$listItemCardVerticalPadding;
        left: 0;
        background-color: $neutral-200;
        width: 1px;
        height: calc(100% + (#{$listItemCardVerticalPadding } * 2))
      }
    }
  }

  // O.R. prime
  .p-avatar {
    margin-right: 0.5rem;

    &:not(.p-avatar-image) {
      background-color: $neutral-200;
    }

    .p-toolbar & {
      background-color: $neutral-300;

      &.p-avatar-circle {
        border-radius: 50%;
      }
    }
  }

  i.material-symbols-outlined {
    transition: opacity 200ms ease-in-out;
  }

  /** component modifiers **/
  // heading + caption rendered inline modifier
  &.is-inlined {
    .list-item_content {
      flex-direction: row;
      align-items: center;

      &-subline {
        padding-left: 0.188rem;
        font-size: .875rem;
      }
    }
  }

  // content modifier; flip order of title and caption elements
  &.is-reversed {
    .list-item_content {
      flex-direction: column-reverse;
    }
  }

  // list item custom skeleton style
  &.is-loading {
    display: flex;
    align-items: center;
    height: 3rem;
    padding: 0.5rem 1rem;
    flex: none;

    // important rules required due to prime forcing unwanted inline rules.
    > .rectangle {
      display: block;
      flex: 1;
      height: 1.25rem !important;

      .side-nav.is-collapsed & {
        display: none;
      }
    }

    > .square {
      margin-right: 0.5rem;
      height: 2rem !important;
      width: 2rem !important;

      .side-nav.is-collapsed & {
        margin-right: 0;
        height: 2.25rem !important;
        width: 2.25rem !important;
      }
    }
  }

  // in case of using <Link> instead of <ListItem> w/ listItem class supplied to element.
  &.link-item {
    color: inherit;
    text-decoration: none;
    transition: color $transitionDuration ease-in-out;

    .p-avatar {
      box-shadow: $elevation-0;
      transition: box-shadow $transitionDuration ease-in-out;

      .p-avatar-text {
        color: $secondaryTextColor;
      }
    }

    &:hover {
      color: $primary-500;

      .p-avatar {
        box-shadow: $elevation-1;
      }
    }
  }
}