$fieldElevation: 1;
$formMaxWidth: 850px;
$formMaxWidthLg: 1450px;
$formWrappedSpacingX: 1.5rem;
$formWrappedSpacingY: 1rem;

.form {
  position: relative;
  max-width: $formMaxWidth;

  // styles applied to forms unwrapped by components.
  &-is-wrapped {
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid $neutral-200;
    border-radius: $border-radius-sm;
    background-color: $neutral-0;

    .form-actions {
      background-color: $neutral-100;
      padding: $formWrappedSpacingY $formWrappedSpacingX;
    }

    .wrapped-form_header {
      padding: $formWrappedSpacingY $formWrappedSpacingX;
      border-bottom: 1px solid $neutral-200;
    }

    .form-fields {
      padding: $formWrappedSpacingY $formWrappedSpacingX;
    }
  }

  &-segment {
    display: flex;
    flex-direction: column;
    &:nth-of-type(n + 2) {
      margin-top: 0.75rem;
    }

    &_row {
      display: flex;
      flex-direction: row;

      > :not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }

  .input-wrap {
    position: relative;
    display: flex;

    &.has-error {
      .icon {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        transform: translateY(-50%);
        color: $dangerColor;
        z-index: $fieldElevation + 1;
      }
    }

    &.input-disabled:after {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      transform: translateY(-50%);
      content: "lock";
      font-family: "Material Icons";
      font-size: 1.5rem;
      color: $neutral-200;
      z-index: $fieldElevation + 1;
    }
  }

  &-field {
    flex: 1;
    position: relative;
    z-index: $fieldElevation; // set all inputs to sit above floated error messages.

    &.p-inputtext.p-invalid.p-component,
    .has-error & {
      border-color: $dangerColor;
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // actions within Modal component
    .modal & {
      display: flex;
      justify-content: space-between;
      padding: 1rem $modalContentSpacing;
      background-color: $neutral-50;
      margin: ($modalContentSpacing) (-$modalContentSpacing) (-$modalContentSpacing) (-$modalContentSpacing);

      button.p-button {
        width: unset;
        flex: none;
      }
    }

    // actions within InlineDrawer component
    .inline-drawer & {
      position: sticky;
      display: flex;
      background-color: $shade-0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: $fieldElevation + 1;

      border-top: 1px solid $neutral-200;
      padding: 0.75rem 1rem;
      margin: 1.5rem -1.5rem -1rem -1.5rem;

      button.p-button {
        width: unset;
        margin: 0 0.25rem;
        flex: 1;
        justify-content: center;

        .p-button-label {
          flex: none;
        }
      }
    }
  }

  // isSubmitting visual UI
  .form-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($shade-0, 0.5);
    z-index: 1;

    .modal & {
      top: -$modalContentSpacing;
      right: -$modalContentSpacing;
      left: -$modalContentSpacing;
    }

    .inline-drawer & {
      top: -$drawerContentPadding;
      right: -$drawerContentPadding;
      left: -$drawerContentPadding;
    }

    .p-progressbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  // InlineDrawer specific
  .inline-drawer &  {
    display: flex;
    flex-direction: column;
    height: 100%;

    .form-fields {
      flex: 1;
      margin-bottom: 1rem;
    }

    .form-actions {
      margin: 0 -1.5rem -1rem -1.5rem;
    }
  }

  // kit designer/editor specific
  &.kit-editor {
    transition: max-width $transitionDuration ease-in-out;

    @include ultrahd {
      max-width: $formMaxWidthLg;
    }
  }
}