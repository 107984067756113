.bordered-container {
  padding: 0.75rem;
  border: 1px solid $neutral-100;
  border-radius: $border-radius-sm;
}

.p-buttonset {
  display: flex;

  .actions-td & {
    justify-content: flex-end;
  }
}