$modalContentSpacing: 1.25rem;

.p-dialog.modal,
.p-confirm-dialog.modal {
  min-width: 550px;
  border-radius: 8px;
  overflow: hidden;

  // modal header
  .p-dialog-header {
    padding: 1rem 1.25rem;
    border-color: $neutral-200;

    .p-dialog-title {
      display: flex;
      text-transform: uppercase;
      letter-spacing: $typeTrackingSml;

      .title:not(.is-bold) {
        font-weight: 500;
      }

      .title:nth-of-type(2) {
        margin-left: 6px;
      }
    }

    .p-dialog-header-icons {
      align-self: unset;
      margin-right: -0.375rem;

      .p-dialog-titlebar-icon.p-link {
        display: flex;

        &:nth-of-type(n + 2) {
          margin-left: 0.75rem;
        }
      }

      // override maximise icon
      button.p-dialog-titlebar-icon {
        color: $neutral-400;
      }

      button.p-dialog-header-icon.p-dialog-header-close {
        display: none;
      }
    }
  }

  .p-dialog-content {
    padding: $modalContentSpacing;
  }

  // modal footer
  .p-dialog-footer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    background-color: $neutral-50;
    border: none;

    button:only-child {
      margin-left: auto;
    }

    button:last-of-type {
      margin-right: 0;
    }

    &_actions {
      flex: 1;
    }
  }

  // tab variation
  &.has-tabs,
  &.form-stepper {
    .p-dialog-content {
      padding: 0;

      .tab-content {
        padding: 1.25rem;
      }
    }
  }

  &.is-basic {
    min-width: 250px;
    max-height: 90vh;

    .p-dialog-header {
      padding-bottom: 0;
      border-bottom: none;
    }

    .p-dialog-content {
      padding-top: 0.75rem;
    }
  }

  // over-rides to confirmDialog
  .p-confirm-dialog-message {
    margin: 0;
    width: 100%;
  }

  // delete modal specific
  &.delete-modal {
    .modal-content {
      p.delete-msg {
        margin-bottom: 1rem;
      }

      .list-item {
        .list-item_content {
          flex: 2;

          > .list-item_content {
            flex: 1;
          }
        }

        hr {
          border-color: $borderColor;
          height: 2rem;
          margin: 0 1rem;
        }
      }
    }
  }
}