.p-chip {
  color: $shade-0;

  .icon.material-symbols-outlined {
    color: $shade-0;
    padding: 0.375rem 0;
    font-size: 1.25rem;

    & ~ .p-chip-text {
      margin-left: 0.125rem;
    }
  }

  // custom severity settings
  &--success {
    background-color: $successMessageBg;
  }
  &--error {
    background-color: $errorMessageBg;
  }
  &--warn {
    background-color: $warningMessageBg;
  }
  &--info {
    background-color: $infoMessageBg;
  }

  // specific to usage within prime table component
  .p-datatable & {
    margin: 0.188rem 0;
  }
}