.error-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  // buttons
  &_button-group {
    margin-top: 1.5rem;

    button:nth-of-type(n + 2) {
      margin-left: 0.5rem;
    }

    &.is-compact {
      margin-top: 1rem;
    }

    &.is-enlarged,
    &.is-fullscreen {
      margin-top: 2rem;
    }
  }

  // esg
  &_state-graphic {
    max-height: 250px;

    &.is-compact {
      max-height: 150px;
    }

    &.is-enlarged {
      max-height: 350px;
    }

    &.is-fullscreen {
      max-height: 450px;
    }
  }

  // heading
  &_title {
    margin-top: 1rem;

    .is-enlarged,
    .is-fullscreen & {
      margin-top: 1.5rem;
    }
  }

  // description
  &_desc {
    margin-top: 1rem;

    .is-compact & {
      margin-top: 0.5rem;
    }

    .is-enlarged,
    .is-fullscreen & {
      margin-top: 1.5rem;
    }
  }

  &-fullscreen {
    height: calc(100vh - ($navHeight + ($pageInnerPadding * 2)));
    width: calc(100vw - ($pageInnerPadding * 2));
  }

  // alignments
  &.is-aligned {
    &--right {
      align-items: flex-end;
    }

    &--left {
      align-items: flex-start;
    }
  }
}