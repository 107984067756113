$stdTitleWeight: 800;
$lgtTitleWeight: 600;

.rm-theme .title {
  font-family: $family-primary;
  color: $primaryTextColor;

  > %headings:not(.is-bold) {
    font-weight: $stdTitleWeight;
    letter-spacing: $typeTrackingSml;
  }

  &.is-secondary {
    color: $secondaryTextColor;
  }

  &.is-all-caps {
    text-transform: uppercase;
  }

  &.is-small {
    font-size: 0.625rem;
    line-height: 0.625rem;
    letter-spacing: 0.05rem;
    font-weight: $lgtTitleWeight;

  > %headings {
      font-weight: $lgtTitleWeight;
    }
  }

  &.is-light {
    font-weight: $lgtTitleWeight;

  > %headings {
      font-weight: $lgtTitleWeight;
    }
  }
}